import React, { useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import useMergePrismicPreviewData from '../utils/use-merge-prismic-preview-data-fix';
import Navbar from './Navbar';
import '../scss/header.scss';
import menuIcon from '../images/icons/menu.svg';
import closeIcon from '../images/icons/close.svg';

const Header = () => {
  const [ navbarOpen, setNavbarOpen ] = useState(false);

  const queryData0 = useStaticQuery(graphql`
    query HeaderQuery {
      prismicGlobalOptions {
        _previewable
        data {
          header_logo { alt, url }
        }
      }
    }
  `);
  const queryData = useMergePrismicPreviewData(queryData0);

  const header = queryData.prismicGlobalOptions;
  const headerData = header.data.header_logo;

  return (
    <header className={`site-header container-fluid`}>
      <Link to="/" className={`logo-wrapper`}>
        <img src={headerData.url} alt={headerData.alt ?? 'Gallium Studios'} />
      </Link>

      <Navbar
        navbarOpen={navbarOpen}
        setNavbarOpen={setNavbarOpen}
        headerData={headerData}
      />

      <a
        href="#"
        className="menu-toggle"
        onClick={() => setNavbarOpen(!navbarOpen)}
        aria-controls="main-menu"
      >
        {navbarOpen ? <img className="menu-icon" src={closeIcon} alt="Close navigation menu"/> :
          <img className="menu-icon" src={menuIcon} alt="Open navigation menu"/>
        }
      </a>
    </header>
  );
};

export default Header;
