import * as React from 'react';
import { graphql } from 'gatsby';
import gsap, { Power2 } from 'gsap';
import '../scss/liquidAndLogoaAnimatSlice.scss';

export const LiquidAndLogoAnimat = ({ slice }) => {
  const liquidRef = React.useRef();

  React.useEffect(() => {
    gsap.fromTo(liquidRef.current, {
      duration: 5,
      y: '-50%'
    },
    {
      duration: 7,
      y: '0%',
      ease: Power2.easeOut
    });
  }, [ ]);

  return (
    <>
      <img ref={liquidRef} className={`liquid`}
        src={slice.data.liquid_background.url}
        alt={slice.data.liquid_background.alt ?? ''}
      />
      <div className={"liquid-and-logo-animation"}>

        <img className={`logo`}
          src={slice.data.logo.url}
          alt={slice.data.logo.alt ?? ''} />
      </div>
    </>
  );
};

export const query = graphql`
fragment liquidAndLogoAnimation on PrismicLiquidAndLogoAnimat {
  _previewable
  type

  data {
    liquid_background { alt, url }
    logo { alt, url }
  }
}
`;
