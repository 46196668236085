import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import useMergePrismicPreviewData from '../utils/use-merge-prismic-preview-data-fix';
import Header from './Header';
import Footer from './Footer';
import '../styles/reset.css';
import '../scss/index.scss';

const Layout = ({ path, children }) => {
  const data0 = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }

      prismicGlobalOptions {
        _previewable
        data {
          site_title { text }
        }
      }
    }
  `);

  const data = useMergePrismicPreviewData(data0);

  const siteTitle = data.prismicGlobalOptions.data.site_title.text;

  const canonicalLink = path ? (
    <link rel="canonical" href={`${data.site.siteMetadata.siteUrl}${path}`} />
  ) : null;

  return (
    <>
      <Helmet titleTemplate={`${siteTitle} - %s`} defaultTitle={siteTitle}>
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no" />
        <meta name="theme-color" content="#e59c14" />
        <meta name="og:site_name" content={siteTitle} />
        {/*<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />*/}
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#e59c14" />
        <meta name="msapplication-TileColor" content="#e59c14" />
        {canonicalLink}
      </Helmet>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
