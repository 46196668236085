import React from 'react';
import Link from './link';
import '../scss/socialIcons.scss';

const SocialIcons = ({ socialLinks }) => {
  return (
    <div className={`social-icons`}>
      {socialLinks && socialLinks.map((item, index) => {
        return (
          <Link to={item.link} key={index}>
            <img className={`social-icon`} src={item.icon.fixed.src} alt="" />
          </Link>
        );
      })}
    </div>
  );
};

export default SocialIcons;
