import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import useMergePrismicPreviewData from '../utils/use-merge-prismic-preview-data-fix';
import { RichText } from 'prismic-reactjs';
import EmailSignupForm from './forms/EmailSignupForm';
import SocialIcons from './SocialIcons';
import Link from './link';
import '../scss/footer.scss';

function Footer () {
  const data0 = useStaticQuery(graphql`
    query FooterQuery {
      prismicGlobalOptions {
        _previewable
        data {
          about_gallium_studios { raw }
          footer_logo { alt, url }
          sitemap_heading { raw }
          community_heading { raw }
          register_link_text
          login_link_text
          signup_link { raw }
          login_link { raw }
          social_heading { raw }
          copyright { raw }

          social_links {
            link { raw }
            icon {
              alt
              fixed(imgixParams: {height: 32, width: 32}, height: 32, width: 32) {
                src
              }
            }
          }

          site_map_links {
            item {
              raw
              document {
                ... on PrismicPage {
                  _previewable
                  data { navigation_title }
                }
                ... on PrismicNewsPage {
                  _previewable
                  data { navigation_title }
                }
              }
            }
          }

          secondary_site_map_links {
            item {
              raw
              document {
                ... on PrismicPage {
                  _previewable
                  data { navigation_title }
                }
                ... on PrismicNewsPage {
                  _previewable
                  data { navigation_title }
                }
              }
            }
          }

        }
      }
    }
  `);
  const data = useMergePrismicPreviewData(data0);

  const footer = data.prismicGlobalOptions.data;
  const socialLinks = footer.social_links;

  return (
    <footer>
      <div className={`container footer-container`}>
        <div className="logo">
          <img src={footer.footer_logo.url} alt={footer.footer_logo.alt ?? 'Gallium Studios'}/>
        </div>

        <div className={`container-wrapper`}>
          <div className={`category`}>
            <RichText render={footer.about_gallium_studios.raw}/>
          </div>

          <div className={`category`}>
            <RichText render={footer.social_heading.raw}/>
            <SocialIcons socialLinks={socialLinks} />
            <div className={`category contact-form-container`}>
              <div className={`contact-form`} id="footer_signup_newsletter">
                <EmailSignupForm white={true} />
              </div>
            </div>
          </div>

          <div className={`category site-map-container`}>
            <RichText render={footer.sitemap_heading.raw}/>
            <ul className={`site-map`}>
              {footer.site_map_links.map((link, index) => {
                return (
                  <li key={`link-${index}`}>
                    <Link to={link.item} className={`subtext`}>
                      {link.item.document?.data?.navigation_title}
                    </Link>
                  </li>
                );
              })}
            </ul>

            <hr />

            <ul className={`site-map`}>
              {footer.secondary_site_map_links.map((link, index) => {
                return (
                  <li key={`link-${index}`}>
                    <Link to={link.item} className={`subtext`}>
                      {link.item.document?.data?.navigation_title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className={`category`}>
            <RichText render={footer.community_heading.raw}/>
            <ul className={`site-map`}>
              <li>
                <Link to={footer.signup_link} className={`subtext`}>
                  {footer.register_link_text}
                </Link>
              </li>
              <li>
                <Link to={footer.login_link} className={`subtext`}>
                  {footer.login_link_text}
                </Link>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div className={`category copyright container`}>
        <RichText render={footer.copyright.raw}/>
      </div>
    </footer>
  );
}

export default Footer;
