export default function getJsonp (uri, callbackParameter = 'callback') {
  return new Promise((resolve, reject) => {
    const callbackFunctionName = `callback_${Date.now()}`;

    const scriptEl = document.createElement('script');
    scriptEl.id = callbackFunctionName;
    scriptEl.type = "text/javascript";

    uri = new URL(uri);
    uri.searchParams.set(callbackParameter, callbackFunctionName);
    scriptEl.src = uri.toString();

    const scriptTimeout = setTimeout(() => {
      clearTimeout(scriptTimeout);
      scriptEl.remove();

      window[callbackFunctionName] = () => {
        delete window[callbackFunctionName];
      };
      reject();
    }, 5000);

    window[callbackFunctionName] = function callback (...args) {
      clearTimeout(scriptTimeout);
      delete window[callbackFunctionName];
      scriptEl.remove();
      resolve(...args);
    };

    document.body.appendChild(scriptEl);
  });
}
