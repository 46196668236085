import React, { useState, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import useMergePrismicPreviewData from '../../utils/use-merge-prismic-preview-data-fix';
import TextField from '@material-ui/core/TextField';
import { InputAdornment, IconButton } from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';
import getJsonp from '../../utils/get-jsonp';
import '../../scss/email-signup-form.scss';

export default function EmailSignupForm () {
  const ref = useRef(null);
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false);
  const [ submitting, setSubmitting ] = useState(false);

  const queryData0 = useStaticQuery(graphql`
    query EmailSignupFormQuery {
      prismicGlobalOptions {
        _previewable
        data {
          mailchimp_signup_form_target { url }
        }
      }
    }
  `);
  const queryData = useMergePrismicPreviewData(queryData0);

  const header = queryData.prismicGlobalOptions;
  const FORM_ENDPOINT = header.data.mailchimp_signup_form_target.url;

  const handleSubmit = (ev) => {
    ev.preventDefault();

    const formEl = ref.current;

    if (!formEl.reportValidity()) return;

    const email = formEl['newsletter-signup-form-email'].value;

    async function _handleSubmit () {
      setSubmitting(true);

      let uri = FORM_ENDPOINT;
      getJsonp(`${uri}&_=${Date.now()}&EMAIL=${encodeURIComponent(email)}`, 'c').then(() => {
        setShowSuccessMessage(true);
        setSubmitting(false);
      }, (err) => {
        console.error(err.stack);
        setSubmitting(false);
      });
    }

    _handleSubmit().catch(console.error);
  };

  if (showSuccessMessage) {
    return (
      <div className="newsletter-signup-form category">
        <div className={`category-title`}>Get email updates from Gallium</div>
        <div className="success-message">You are signed up for email updates</div>
      </div>
    );
  }

  return (
    <form ref={ref} className={`email-signup-form`} onSubmit={handleSubmit}>
      <div className={`form-headline`}>Get email updates from Gallium</div>

      <TextField
        placeholder="Your email address"
        name="newsletter-signup-form-email"
        required={true}
        fullWidth={true}
        type="email"
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={handleSubmit} disabled={submitting} aria-label={`Submit form`}>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </form>
  );
}
