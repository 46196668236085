import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { linkResolver } from '../utils/linkResolver';

export default function Link ({ to, children, ...props }) {
  to = to.raw ? to.raw : to;

  if (to.link_type === 'Document') {
    return (
      <GatsbyLink
        to={linkResolver(to)}
        target={to.target}
        {...props}
      >
        {children}
      </GatsbyLink>
    );
  }

  if (to.link_type === 'Any') {
    return (
      <a
        href={to.url ?? '#'}
        target={to.target}
        rel={to.target === "_blank" ? 'noreferrer' : null}
        {...props}
      >
        {children}
      </a>
    );
  }

  if (to.link_type === 'Web') {
    return (
      <a
        href={to.url.replace(/^http(s)?:\/\/#/, '#')}
        target={to.target}
        rel={to.target === "_blank" ? 'noreferrer' : null}
        {...props}
      >
        {children}
      </a>
    );
  }

  throw new Error(`Invalid link_type for Link ${JSON.stringify({ to })}`);
}
