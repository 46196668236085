import React from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import useMergePrismicPreviewData from '../utils/use-merge-prismic-preview-data-fix';
import Link from './link';
import closeIcon from '../images/icons/close.svg';

const Navbar = ({ navbarOpen, setNavbarOpen, headerData }) => {
  const queryData0 = useStaticQuery(graphql`
    query NavbarQuery {
      prismicGlobalOptions {
        _previewable
        data {
          navigation {
            item {
              raw
              document {
                ... on PrismicPage {
                  _previewable
                  data { navigation_title }
                }
                ... on PrismicNewsPage {
                  _previewable
                  data { navigation_title }
                }
              }
            }
            itemtitle
          }

          signup_button_text
          signup_link { raw }

          login_link_text
          login_link { raw }
        }
      }
    }
  `);
  const queryData = useMergePrismicPreviewData(queryData0);

  const globalOptions = queryData.prismicGlobalOptions;
  const navLinks = globalOptions.data.navigation;
  const signupBtnText = globalOptions.data.signup_button_text;
  const signupLink = globalOptions.data.signup_link;
  const loginBtnText = globalOptions.data.login_link_text;
  const loginLink = globalOptions.data.login_link;

  return (
    <nav id={"main-menu"} className={navbarOpen ? 'open' : ''}>
      <div className="brand-container">
        <GatsbyLink to="/" className={`logo-wrapper`}>
          <img src={headerData.url} alt={headerData.alt ?? 'Gallium Studios'} />
        </GatsbyLink>
        <a
          href="#"
          className="menu-toggle"
          onClick={() => setNavbarOpen(false)}
          aria-controls="main-menu"
        >
          <img className="menu-icon" src={closeIcon} alt="Close navigation menu"/>
        </a>
      </div>
      <ul>
        {navLinks.map((navItem, index) => {
          let linkText;

          if (navItem.item.raw?.link_type === "Web")
          {
            linkText = navItem.itemtitle
          }
          else
          {
            linkText = navItem.item.document?.data?.navigation_title;
          }
          return (
            <li key={`link-${index}`}>
              <Link to={navItem.item}>
                {linkText}
              </Link>
            </li>
          );
        })}
      </ul>
      <div className={`action-wrapper`}>
        <Link className="sign-up" to={signupLink}>{signupBtnText}</Link>
        <Link className="button" to={loginLink}>{loginBtnText}</Link>
      </div>
    </nav>
  );
};

export default Navbar;
